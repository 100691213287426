/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { graphql } from "gatsby"

import { contentPositionerCss } from "components/shared/styles"
import Header from "components/header"
import Footer from "components/footer"
import Layout from "../../layouts"
import SiteScreenshotDisplay from "components/site-screenshot-display"
import PageMetadata from "components/page-metadata"
import { SkipNavTarget } from "components/shared/components/SkipNav"

import StarterHeader from "components/starters/details-sections/header"
import StarterMeta from "components/starters/details-sections/meta"
import StarterActions from "components/starters/details-sections/actions"
import StarterInstallation from "components/starters/details-sections/installation"
import StarterDependencies from "components/starters/details-sections/dependencies"
import StarterDescription from "components/starters/details-sections/description"
import StarterFeatures from "components/starters/details-sections/features"
import StarterTags from "components/starters/details-sections/tags"

const NUM_DEPS_TO_SHOW = 7

const hasDeps = allDeps => {
  return allDeps[0] !== "no data"
}

const StarterPage = ({ data: { wpStarter } }) => {
  const [showAllDeps, setShowAllDeps] = useState(false)
  const {
    starterTags,
    starterFields: { description, features, repoUrl, url: demoUrl, screenshot },
    githubMetaFields,
  } = wpStarter

  // preprocessing of dependencies
  const { miscDependencies = [], gatsbyDependencies = [] } = githubMetaFields
  const allDeps = [
    ...gatsbyDependencies.map(([name]) => name),
    ...miscDependencies.map(([name]) => name),
  ]
  const shownDeps = showAllDeps ? allDeps : allDeps.slice(0, NUM_DEPS_TO_SHOW)
  const showMore = !showAllDeps && allDeps.length - shownDeps.length > 0

  const updateShowAllDeps = () => {
    setShowAllDeps(true)
  }

  return (
    <Layout>
      <Header isFullWidth />
      <main css={theme => [contentPositionerCss({ theme })]}>
        <SkipNavTarget />
        <PageMetadata
          title={`${githubMetaFields.stub}: Gatsby Starter`}
          description={description}
          image={screenshot.localFile.childImageSharp.resize}
        />
        <StarterHeader stub={githubMetaFields.stub} />
        <div
          css={theme => ({
            display: `grid`,
            gap: theme.space[7],
            gridTemplateColumns: `100%`,
            gridTemplateAreas: `
              'meta'
              'actions'
              'installation'
              'screenshot'
              'description'
              'features'
              'tags'
              'dependencies'
            `,
            [theme.mediaQueries.desktop]: {
              columnGap: theme.space[13],
              gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
              gridTemplateAreas: `
               'meta'
               'actions'
               'installation'
               'description'
               'features'
               'dependencies'
               'tags'
              `,
            },
          })}
        >
          <StarterMeta
            starter={githubMetaFields}
            repoUrl={repoUrl}
            css={{ gridArea: "meta" }}
          />
          <StarterActions
            repoName={githubMetaFields.stub}
            imageSharp={screenshot}
            demo={demoUrl}
            repoUrl={repoUrl}
            css={theme => ({
              gridArea: "actions",

              [theme.mediaQueries.desktop]: {
                alignSelf: "flex-end",
              },
            })}
          />
          <StarterInstallation
            repoName={githubMetaFields.stub}
            repoUrl={repoUrl}
            css={theme => ({
              gridArea: "installation",
              marginTop: `-${theme.space[5]}`,
            })}
          />
          <SiteScreenshotDisplay
            imageSharp={screenshot.localFile.childImageSharp.gatsbyImageData}
            alt={`Screenshot of ${githubMetaFields.stub}`}
            css={theme => ({
              width: "100%",
              gridArea: "screenshot",
              [theme.mediaQueries.desktop]: {
                width: "52rem",
                gridColumn: 2,
                gridRow: `1 / -1`,
              },
            })}
          />
          <StarterDescription
            description={description}
            css={theme => ({
              gridArea: "description",
              margin: `${theme.space[7]} 0`,
            })}
          />
          <StarterFeatures features={features} css={{ gridArea: "features" }} />
          <StarterTags tags={starterTags.nodes} css={{ gridArea: "tags" }} />
          {hasDeps(allDeps) ? (
            <StarterDependencies
              allDeps={allDeps}
              shownDeps={shownDeps}
              showMore={showMore}
              updateShowAllDeps={updateShowAllDeps}
              css={{ gridArea: "dependencies" }}
            />
          ) : null}
        </div>
      </main>
      <Footer />
    </Layout>
  )
}

export default StarterPage

export const pageQuery = graphql`
  query StarterDetailsQuery($id: String!) {
    wpStarter(id: { eq: $id }) {
      ...StarterDetails
    }
  }

  fragment StarterDetails on WpStarter {
    id
    title
    starterFields {
      description
      features
      repoUrl
      url
      screenshot {
        id
        localFile {
          childImageSharp {
            id
            gatsbyImageData(layout: CONSTRAINED, width: 1000)
            resize(
              width: 1500
              height: 1500
              cropFocus: CENTER
              toFormat: JPG
            ) {
              src
              width
              height
            }
          }
        }
      }
    }
    starterTags {
      nodes {
        id
        name
      }
    }
    githubMetaFields {
      slug
      stub
      name
      description
      stars
      lastUpdated
      owner
      githubFullName
      gatsbyMajorVersion
      allDependencies
      gatsbyDependencies
      miscDependencies
    }
  }
`
