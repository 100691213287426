/** @jsx jsx */
import { jsx } from "theme-ui"
import { MdLaunch } from "react-icons/md"
import { AnchorButton } from "gatsby-interface"

import DeployToCloudButton from "../DeployToCloudButton"
import ShareMenu from "../../share-menu"

const Actions = ({ repoName, imageSharp, demo, repoUrl, ...rest }) => (
  <div
    css={theme => ({
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      gap: theme.space[4],

      "& > *": { flexShrink: 0 },
    })}
    {...rest}
  >
    <DeployToCloudButton repoUrl={repoUrl} />
    <AnchorButton
      size="M"
      variant="SECONDARY"
      href={demo}
      rightIcon={<MdLaunch />}
      data-ld="btn-visit-demo"
    >
      Visit demo
    </AnchorButton>
    <ShareMenu
      url={repoUrl}
      title={`Check out ${repoName} on the @gatsbyjs Starter Showcase!`}
      image={imageSharp.localFile.childImageSharp.resize.src}
      variant="GHOST"
    />
  </div>
)

export default Actions
