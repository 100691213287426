/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { MdShare } from "react-icons/md"
import { FaPinterestP, FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa"
import { visuallyHiddenCss } from "./shared/styles/a11y"
import { IconAnchorButton } from "gatsby-interface"

const objectToParams = object =>
  `?` +
  Object.keys(object)
    .filter(key => !!object[key])
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join(`&`)

const shareMenuWrapperStyles = {
  position: `relative`,
}

const ShareMenuItem = ({ href, icon, children }) => (
  <IconAnchorButton
    icon={icon}
    size="M"
    target="_blank"
    rel="noopener noreferrer"
    variant="GHOST"
    href={href}
    title={children}
  >
    {children}
  </IconAnchorButton>
)

class ShareMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.shareMenu = this.shareMenu.bind(this)
    this.clickOutsideShareMenu = this.clickOutsideShareMenu.bind(this)
    this.setShareBtnRef = this.setShareBtnRef.bind(this)
    this.setShareMenuRef = this.setShareMenuRef.bind(this)
  }

  componentDidMount() {
    document.addEventListener(`mousedown`, this.clickOutsideShareMenu)
  }

  componentWillUnmount() {
    document.removeEventListener(`mousedown`, this.clickOutsideShareMenu)
  }

  setShareBtnRef(node) {
    this.shareBtnref = node
  }

  setShareMenuRef(node) {
    this.shareMenuRef = node
  }

  clickOutsideShareMenu(event) {
    const { open } = this.state
    if (
      open &&
      !this.shareBtnref.contains(event.target) &&
      !this.shareMenuRef.contains(event.target)
    ) {
      this.shareMenu()
    }
  }

  shareMenu() {
    const { open } = this.state
    this.setState({
      open: !open,
    })
  }

  render() {
    const { open } = this.state

    const { url, title, image, className } = this.props
    return (
      <div sx={shareMenuWrapperStyles}>
        <IconAnchorButton
          variant={this.props.variant || "PRIMARY"}
          onClick={this.shareMenu}
          sx={{
            bg: `white`,
            border: 0,
            borderRadius: 2,
            color: `gatsby`,
            cursor: `pointer`,
            height: 36,
            width: 36,
            paddingTop: `7.5px`,
          }}
          ref={this.setShareBtnRef}
          className={className}
          icon={<MdShare />}
          size="M"
        >
          <span sx={visuallyHiddenCss}>Share</span>
        </IconAnchorButton>
        {open && (
          <div
            css={theme => ({
              background: theme.colors.white,
              boxShadow: theme.shadows.floating,
              left: `50%`,
              padding: theme.space[4],
              position: "absolute",
              top: 44,
              transform: `translateX(-50%)`,
              display: "grid",
              gap: theme.space[3],
              borderRadius: theme.radii[3],
              zIndex: theme.zIndices.modals,
            })}
            ref={this.setShareMenuRef}
          >
            <ShareMenuItem
              href={`https://pinterest.com/pin/create/button/${objectToParams({
                url: url,
                media: image,
                description: title,
              })}`}
              icon={<FaPinterestP />}
            >
              Share on Pinterest
            </ShareMenuItem>
            <ShareMenuItem
              href={`https://www.linkedin.com/shareArticle${objectToParams({
                mini: `true`,
                url: url,
                title: title,
              })}`}
              icon={<FaLinkedin />}
            >
              Share on LinkedIn
            </ShareMenuItem>
            <ShareMenuItem
              href={`https://www.facebook.com/sharer.php${objectToParams({
                u: url,
                t: title,
              })}`}
              icon={<FaFacebook />}
            >
              Share on Facebook
            </ShareMenuItem>
            <ShareMenuItem
              href={`https://twitter.com/share${objectToParams({
                url: url,
                text: title,
              })}`}
              icon={<FaTwitter />}
              title="Share on Twitter"
            >
              Share on Twitter
            </ShareMenuItem>
          </div>
        )}
      </div>
    )
  }
}

export default ShareMenu
