import * as React from "react"
import { AnchorButton } from "gatsby-interface"

interface DeployToCloudButtonProps {
  repoUrl: string
}

const DeployToCloudIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#fff">
      <path d="m9.61297 4.19974c0 .46722-.37875.84597-.84597.84597s-.84598-.37875-.84598-.84597.37876-.84598.84598-.84598.84597.37876.84597.84598z" />
      <path d="m7.63653 17.4961 1.52333-.0001c-.09003-.4135-.14351-.8406-.15689-1.2779h-1.36661c-1.40166 0-2.53793-1.1362-2.53793-2.5379s1.13627-2.5379 2.53793-2.5379c.50002 0 .74833.1043.95494.1912.10547.0443.20007.0841.31175.1031.16442.0279.30403 0 .48578-.119.22141-.2346.35047-.4541.4027-.7887.23177-1.48486 1.96337-3.22654 4.26727-3.22654 1.53 0 2.8827.75904 3.7022 1.92111.5806.15047 1.1319.37357 1.6436.65903-.7935-2.22419-2.9184-3.81609-5.4151-3.81609-1.4086 0-2.6988.50668-3.6984 1.34772-.5518-.70131-1.40822-1.15168-2.36984-1.15168-1.66447 0-3.01379 1.34932-3.01379 3.01379 0 .51553.12944 1.00086.35759 1.42516-.87727.6982-1.43962 1.7752-1.43962 2.9836 0 2.1048 1.70628 3.8111 3.81109 3.8111z" />
      <path d="m3.69171 9.27591c0 .46722-.37876.84599-.84598.84599s-.84597-.37877-.84597-.84599.37875-.84598.84597-.84598.84598.37876.84598.84598z" />
      <path d="m5.38312 5.04739c0 .46722-.37876.84598-.84598.84598s-.84598-.37876-.84598-.84598.37876-.84597.84598-.84597.84598.37875.84598.84597z" />
      <path
        clipRule="evenodd"
        d="m10.5 16c0-3.025 2.475-5.5 5.5-5.5s5.5 2.475 5.5 5.5-2.475 5.5-5.5 5.5-5.5-2.475-5.5-5.5zm1.1786.0786c0 1.0607.4321 2.1607 1.2571 2.9857s1.925 1.2178 3.025 1.2571zm.1178-1.0215 5.1465 5.1465c1.925-.4322 3.3785-2.1607 3.3785-4.2036h-2.75v.7857h1.8857c-.275 1.1786-1.1392 2.1607-2.2785 2.5536l-4.5179-4.5179c.5107-1.375 1.8072-2.3571 3.3393-2.3571 1.1786 0 2.2393.5893 2.9071 1.4928l.5893-.5107c-.7857-1.0607-2.0428-1.7678-3.4964-1.7678-2.0429 0-3.7714 1.4535-4.2036 3.3785z"
        fillRule="evenodd"
      />
    </g>
  </svg>
)

const DeployToCloudButton = ({
  repoUrl,
  ...props
}: DeployToCloudButtonProps) => (
  <AnchorButton
    data-ld="btn-deploy-to-cloud"
    leftIcon={<DeployToCloudIcon />}
    size="M"
    href={`/dashboard/deploynow?url=${repoUrl}`}
    {...props}
  >
    Deploy to Gatsby Cloud
  </AnchorButton>
)

export default DeployToCloudButton
