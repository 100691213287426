/** @jsx jsx */
import { jsx } from "theme-ui"
import { GoMarkGithub as GithubIcon } from "react-icons/go"

const Source = ({ repoUrl, ...rest }) =>
  repoUrl ? (
    <a
      href={repoUrl}
      sx={{
        alignItems: `center`,
        display: `flex`,
        gap: 3,
        lineHeight: `solid`,
        "&&": {
          borderBottom: 0,
          color: `link.color`,
          cursor: `pointer`,
          fontWeight: `body`,
          "&:hover": {
            color: `purple.60`,
          },
        },
      }}
      {...rest}
    >
      <GithubIcon />
      Source
    </a>
  ) : null

export default Source
